<template>
  <v-tooltip top :color="color">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-icon small>{{ icons.mdiInformation }}</v-icon></span
      >
    </template>
    <span>
      <v-row class="my-4">
        <v-col cols="12" class="my-0 py-0">
          <v-icon color="white">{{ icons.mdiInformation }}</v-icon> {{ titulo }}
        </v-col>
        <v-col cols="12" class="my-2 py-0" v-html="mensaje" >
       
        </v-col>
      </v-row>
    </span>
  </v-tooltip>
</template>

<script>
import {mdiInformation} from '@mdi/js'
export default {
  props: {
    titulo: {
      type: String,
      default: 'Importante',
    },
    mensaje: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'info',
    },
  },
  setup() {
    return {
        icons:{
            mdiInformation
        }
    }
  },
}
</script>

<style>
</style>